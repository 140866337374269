import React, { useCallback, useContext, useEffect, useState } from "react";

import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import {
  CheckCircle,
  DeleteOutline,
  Edit,
  Facebook,
  Instagram,
  ReplayOutlined,
  Restore,
} from "@material-ui/icons";
import axios from "axios";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";

import { FacebookLoginClient } from "@greatsumini/react-facebook-login";
import ConfirmationModal from "../../components/ConfirmationModal";
import ConnectionsInatives from "../../components/ConnectionsInatives/ConnectionsInatives";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MetaModal from "../../components/MetaModal";
import OficialWhatsAppModal from "../../components/OficialWhatsAppModal";
import QrcodeModal from "../../components/QrcodeModal";
import ResendMessagesDropdown from "../../components/ResendMessagesDropdown";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import TransferTicketWhatsapp from "../../components/TransferTicketWhatsapp";
import WhatsAppModal from "../../components/WhatsAppModal";
import { getAPPID } from "../../config";
import { useAuthContext } from "../../context/Auth/AuthContext";
import { useSettingsContext } from "../../context/Settings";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import toastError from "../../errors/toastError";
import useMixpanel from "../../hooks/useMixpanel";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cellElelement: {
    whiteSpace: "nowrap",
  },
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #dadde9",
    maxWidth: 450,
  },
  tooltipPopper: {
    textAlign: "center",
  },
  buttonProgress: {
    color: green[500],
  },
}));

const confirmationModalInitialState = {
  action: "",
  title: "",
  message: "",
  whatsAppId: "",
  open: false,
};

const Connections = () => {
  const classes = useStyles();
  const mixpanel = useMixpanel();
  const { whatsApps, loading } = useContext(WhatsAppsContext);
  const { user } = useAuthContext();
  const { getSettingValue } = useSettingsContext();

  const [metas, setMetas] = useState([]);
  const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
  const [oficialWhatsappModalOpen, setOficialWhatsAppModalOpen] =
    useState(false);
  const [metaModalOpen, setMetaModalOpen] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
  const [selectedMeta, setSelectedMeta] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [transferWModal, setTransferWModal] = useState(false);
  const [connectionsCount, setConnectionsCount] = useState(0);
  const [confirmModalInfo, setConfirmModalInfo] = useState(
    confirmationModalInitialState
  );

  const getMetas = async () => {
    try {
      const { data } = await api.get("/meta");
      setMetas(data);
    } catch {
      console.log("Erro ao pegar meta conexões");
    }
  };

  const createPagesFB = async (data, userToken) => {
    try {
      const metaData = {
        name: data?.name,
        pageId: data.id,
        pageToken: data.access_token,
        verifyToken: "teste",
        userToken,
      };
      await api.post("/meta", metaData);
      getMetas();
      toast.success("Facebook Page criado com sucesso");
    } catch (err) {
      toastError(err);
    }
  };

  const createPagesIG = async (data, userToken) => {
    try {
      const instaData = data["instagram_business_account"];
      const name = `${instaData["name"]} - IG`;
      const pageId = instaData["id"];
      const pageToken = data.access_token;
      const verifyToken = "teste";
      const metaData = {
        name,
        pageId,
        pageToken,
        verifyToken,
        userToken,
        faceId: data.id,
      };
      await api.post("/meta", metaData);
      getMetas();
      toast.success("Instagram Page criado com sucesso");
    } catch (err) {
      console.log(err);
      // toastError(err);
    }
  };

  const getPageDataFB = async (dataUser) => {
    const { accessToken, userID } = dataUser.authResponse;
    const { data } = await axios.get(
      `https://graph.facebook.com/${userID}/accounts`,
      {
        params: {
          access_token: accessToken,
        },
      }
    );
    for (const page of data.data) {
      await createPagesFB(page, accessToken);
    }
  };

  const getPageDataIG = async (dataUser) => {
    const { accessToken, userID } = dataUser.authResponse;
    const { data } = await axios.get(
      `https://graph.facebook.com/${userID}/accounts?fields=instagram_business_account{id,name},access_token`,
      {
        params: {
          access_token: accessToken,
        },
      }
    );
    for (const page of data.data) {
      if (page["instagram_business_account"]) {
        await createPagesIG(page, accessToken);
      }
    }
  };

  const loadFB = async () => {
    FacebookLoginClient.clear();
    await FacebookLoginClient.loadSdk("pt_BR");
    FacebookLoginClient.init({ appId: getAPPID(), version: "v16.0" });
  };

  const loginFB = () => {
    FacebookLoginClient.login(
      (data) => {
        getPageDataFB(data);
      },
      {
        scope:
          "email, pages_show_list, pages_messaging, pages_read_engagement, public_profile, pages_manage_metadata, business_management",
      }
    );
  };

  const loginIG = () => {
    FacebookLoginClient.login(
      (data) => {
        getPageDataIG(data);
      },
      {
        scope:
          "public_profile, email, pages_show_list, pages_messaging, instagram_basic, instagram_manage_messages, pages_manage_metadata, instagram_manage_insights, business_management",
      }
    );
  };

  const logOutFB = () => {
    FacebookLoginClient.logout(() => {
      console.log("logout completed!");
    });
  };

  useEffect(() => {
    getMetas();
    loadFB();
  }, []);

  const handleStartWhatsAppSession = async (whatsAppId) => {
    try {
      await api.post(`/whatsappsession/${whatsAppId}`);
    } catch (err) {
      if (err.response.status === 500) {
        toast.error("Whatsapp padrão desconectado");
      }
      toastError(err);
    }
  };

  const handleRequestNewQrCode = async (whatsAppId) => {
    try {
      await api.put(`/whatsappsession/${whatsAppId}`);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenWhatsAppModal = () => {
    setSelectedWhatsApp(null);
    setWhatsAppModalOpen(true);
  };

  const handleOpenOficialWhatsAppModal = () => {
    setSelectedWhatsApp(null);
    setOficialWhatsAppModalOpen(true);
  };

  const handleCloseWhatsAppModal = useCallback(() => {
    setWhatsAppModalOpen(false);
    setSelectedWhatsApp(null);
  }, [setSelectedWhatsApp, setWhatsAppModalOpen]);

  const handleCloseOficialWhatsAppModal = useCallback(() => {
    setOficialWhatsAppModalOpen(false);
    setSelectedWhatsApp(null);
  }, [setOficialWhatsAppModalOpen]);

  const handleCloseMetaModal = useCallback(() => {
    setMetaModalOpen(false);
    setSelectedMeta(null);
  }, [setSelectedMeta, setMetaModalOpen]);

  const handleOpenQrModal = (whatsApp) => {
    setSelectedWhatsApp(whatsApp);
    setQrModalOpen(true);
  };

  const handleCloseQrModal = useCallback(() => {
    setSelectedWhatsApp(null);
    setQrModalOpen(false);
  }, [setQrModalOpen, setSelectedWhatsApp]);

  const handleEditWhatsApp = (whatsApp) => {
    setSelectedWhatsApp(whatsApp);
    setWhatsAppModalOpen(true);
  };

  const handleEditMeta = (meta) => {
    setSelectedMeta(meta);
    setMetaModalOpen(true);
  };

  const handleOpenConfirmationModal = (action, whatsAppId) => {
    if (action === "disconnect") {
      setConfirmModalInfo({
        action: action,
        title: i18n.t("connections.confirmationModal.disconnectTitle"),
        message: i18n.t("connections.confirmationModal.disconnectMessage"),
        whatsAppId: whatsAppId,
      });
    }

    if (action === "delete") {
      setConfirmModalInfo({
        action: action,
        title: i18n.t("connections.confirmationModal.deleteTitle"),
        message: i18n.t("connections.confirmationModal.deleteMessage"),
        whatsAppId: whatsAppId,
      });
    }

    if (action === "deleteMeta") {
      setConfirmModalInfo({
        action: action,
        title: i18n.t("connections.confirmationModal.deleteTitle"),
        message: i18n.t("connections.confirmationModal.deleteMessage"),
        whatsAppId: whatsAppId,
      });
    }

    if (action === "restart") {
      setConfirmModalInfo({
        action: action,
        title: "Deseja reiniciar a conexão?",
        message:
          "Esta ação não poderá ser desfeita e a conexão será reiniciada",
        whatsAppId: whatsAppId,
      });
    }

    if (action === "reset") {
      setConfirmModalInfo({
        action: action,
        title: "Deseja resetar a conexão?",
        message: "Esta ação não poderá ser desfeita e a conexão será resetada",
        whatsAppId: whatsAppId,
      });
    }
    setConfirmModalOpen(true);
  };

  const handleSubmitConfirmationModal = async () => {
    if (confirmModalInfo.action === "disconnect") {
      try {
        await api.delete(`/whatsappsession/${confirmModalInfo.whatsAppId}`);
      } catch (err) {
        toastError(err);
      }
    }

    if (confirmModalInfo.action === "delete") {
      try {
        await api.delete(`/whatsapp/${confirmModalInfo.whatsAppId}`);
        toast.success(i18n.t("connections.toasts.deleted"));
        setConnectionsCount((prevState) => prevState - 1);
      } catch (err) {
        console.log("ERRO", err);
        toastError(err);
      }
    }

    if (confirmModalInfo.action === "deleteMeta") {
      try {
        await api.delete(`/meta/${confirmModalInfo.whatsAppId}`);
        toast.success("Conexão Meta excluída com sucesso");
        setConnectionsCount((prevState) => prevState - 1);
        getMetas();
      } catch (err) {
        toastError(err);
      }
    }

    if (confirmModalInfo.action === "restart") {
      try {
        await api.post(`/restartsession/${confirmModalInfo.whatsAppId}`);
        mixpanel.track("Connection Change", {
          Action: "Restart",
        });
        toast.success("Conexão reiniciada com sucesso");
      } catch (err) {
        toastError(err);
      }
    }

    if (confirmModalInfo.action === "reset") {
      try {
        await api.post(`/resetsession/${confirmModalInfo.whatsAppId}`);
        mixpanel.track("Connection Change", {
          Action: "Reset",
        });
        toast.success("Conexão resetada com sucesso");
      } catch (err) {
        toastError(err);
      }
    }

    setConfirmModalInfo(confirmationModalInitialState);
  };

  const formatNumber = (number) => {
    if (!number) return "";
    if (number.length === 13) {
      return number.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})/, "+$1 ($2) $3-$4");
    }
    if (number.length === 12) {
      return number.replace(/^(\d{2})(\d{2})(\d{4})(\d{4})/, "+$1 ($2) $3-$4");
    }
    return number;
  };

  const handleConnect = async (whatsAppId) => {
    try {
      await api.post(`/whatsappsession/${whatsAppId}`);
      toast.success("Conectado com sucesso");
    } catch (err) {
      toast.error("Erro ao conectar, tente novamente mais tarde");
    }
  };

  const renderActionButtons = (whatsApp) => {
    return (
      <>
        {whatsApp.status === "qrcode" && (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => handleOpenQrModal(whatsApp)}
          >
            {i18n.t("connections.buttons.qrcode")}
          </Button>
        )}
        {whatsApp.status === "DISCONNECTED" && whatsApp.isOficial && (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              handleConnect(whatsApp.id);
            }}
          >
            CONECTAR
          </Button>
        )}
        {whatsApp.status === "DISCONNECTED" && !whatsApp.isOficial && (
          <>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => handleStartWhatsAppSession(whatsApp.id)}
            >
              {i18n.t("connections.buttons.tryAgain")}
            </Button>{" "}
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => handleRequestNewQrCode(whatsApp.id)}
            >
              {i18n.t("connections.buttons.newQr")}
            </Button>
          </>
        )}
        {(whatsApp.status === "CONNECTED" ||
          whatsApp.status === "PAIRING" ||
          whatsApp.status === "TIMEOUT") &&
          !whatsApp.isOficial && (
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => {
                handleOpenConfirmationModal("disconnect", whatsApp.id);
              }}
            >
              {i18n.t("connections.buttons.disconnect")}
            </Button>
          )}
        {(whatsApp.status === "CONNECTED" ||
          whatsApp.status === "PAIRING" ||
          whatsApp.status === "TIMEOUT") &&
          whatsApp.isOficial && (
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              // onClick={() => {
              //   handleOpenConfirmationModal("disconnect", whatsApp.id);
              // }}
              style={{ cursor: "not-allowed" }}
            >
              CONECTADO
            </Button>
          )}
        {whatsApp.status === "OPENING" && (
          <Button size="small" variant="outlined" disabled color="default">
            {i18n.t("connections.buttons.connecting")}
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      <MainContainer>
        <TransferTicketWhatsapp
          open={transferWModal}
          onClose={async () => {
            setTransferWModal(!transferWModal);
          }}
          aria-labelledby="form-dialog-title"
        />
        <ConfirmationModal
          title={confirmModalInfo.title}
          open={confirmModalOpen}
          onClose={setConfirmModalOpen}
          onConfirm={handleSubmitConfirmationModal}
        >
          {confirmModalInfo.message}
        </ConfirmationModal>
        <QrcodeModal
          open={qrModalOpen}
          onClose={handleCloseQrModal}
          whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
        />
        <WhatsAppModal
          open={whatsAppModalOpen}
          // open={true}
          onClose={handleCloseWhatsAppModal}
          whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
        />
        <OficialWhatsAppModal
          open={oficialWhatsappModalOpen}
          onClose={handleCloseOficialWhatsAppModal}
        />
        <MetaModal
          open={metaModalOpen}
          onClose={handleCloseMetaModal}
          metaId={selectedMeta?.id}
          getMetas={getMetas}
        />
        <MainHeader>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Title>{i18n.t("connections.title")}</Title>
            <Title>Total: {connectionsCount}</Title>
          </div>
          {user.profile !== "user" && (
            <MainHeaderButtonsWrapper>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setTransferWModal(true)}
              >
                Transferir tickets
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenWhatsAppModal}
              >
                {i18n.t("connections.buttons.add")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenOficialWhatsAppModal}
              >
                Adicionar Whatsapp API
              </Button>
              {getSettingValue("showIntegrationLogin") === "true" && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Facebook />}
                    onClick={() => loginFB()}
                  >
                    Login
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Instagram />}
                    onClick={() => loginIG()}
                  >
                    Login
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Facebook />}
                    onClick={() => logOutFB()}
                  >
                    LogOut
                  </Button>
                </>
              )}
            </MainHeaderButtonsWrapper>
          )}
        </MainHeader>
        <Paper className={classes.mainPaper} variant="outlined">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">
                  {i18n.t("connections.table.name")}
                </TableCell>
                <TableCell align="center">Número</TableCell>
                <TableCell align="center">
                  {i18n.t("connections.table.session")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("connections.table.lastUpdate")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("connections.table.default")}
                </TableCell>
                <TableCell align="center">Reenvios</TableCell>
                <TableCell align="center">
                  {i18n.t("connections.table.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRowSkeleton />
              ) : (
                <>
                  {whatsApps?.length > 0 &&
                    whatsApps.map(
                      (whatsApp) =>
                        whatsApp.status !== "INATIVE" && (
                          <TableRow key={whatsApp.id}>
                            <TableCell align="center">{whatsApp.id}</TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellElelement}
                            >
                              {whatsApp.name}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellElelement}
                            >
                              {formatNumber(whatsApp.number)}
                            </TableCell>
                            <TableCell align="center">
                              {renderActionButtons(whatsApp)}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.cellElelement}
                            >
                              {format(
                                parseISO(whatsApp.updatedAt),
                                "dd/MM/yy HH:mm"
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {whatsApp.isDefault && (
                                <div className={classes.customTableCell}>
                                  <CheckCircle style={{ color: green[500] }} />
                                </div>
                              )}
                            </TableCell>
                            {user.name === "Administrador" && (
                              <TableCell align="center">
                                <ResendMessagesDropdown
                                  whatsappId={whatsApp.id}
                                />
                              </TableCell>
                            )}
                            <TableCell
                              align="center"
                              className={classes.cellElelement}
                            >
                              {user.profile === "admin" && (
                                <>
                                  <Tooltip title="Editar Conexão">
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        handleEditWhatsApp(whatsApp)
                                      }
                                    >
                                      <Edit />
                                    </IconButton>
                                  </Tooltip>
                                  {!whatsApp.isOficial && (
                                    <>
                                      <Tooltip title="Reiniciar Conexão">
                                        <IconButton
                                          size="small"
                                          onClick={(e) => {
                                            handleOpenConfirmationModal(
                                              "restart",
                                              whatsApp.id
                                            );
                                          }}
                                        >
                                          <ReplayOutlined />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Resetar Conexão">
                                        <IconButton
                                          size="small"
                                          onClick={(e) => {
                                            handleOpenConfirmationModal(
                                              "reset",
                                              whatsApp.id
                                            );
                                          }}
                                        >
                                          <Restore />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  )}
                                  <Tooltip title="Deletar Conexão">
                                    <IconButton
                                      size="small"
                                      onClick={(e) => {
                                        handleOpenConfirmationModal(
                                          "delete",
                                          whatsApp.id
                                        );
                                      }}
                                    >
                                      <DeleteOutline />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                    )}
                  {metas?.length > 0 &&
                    metas.map(
                      (meta) =>
                        meta.status !== "INATIVE" && (
                          <TableRow key={meta.id}>
                            <TableCell align="center">{meta.id}</TableCell>
                            <TableCell align="center">{meta.name}</TableCell>
                            {/* <TableCell align="center">{renderStatusToolTips(meta)}</TableCell> */}
                            <TableCell align="center">
                              {meta.name.includes("IG")
                                ? "Página do Instagram"
                                : "Página do Facebook"}
                            </TableCell>
                            <TableCell align="center">
                              {format(
                                parseISO(meta.updatedAt),
                                "dd/MM/yy HH:mm"
                              )}
                            </TableCell>

                            <TableCell align="center">
                              {/* is default */}
                            </TableCell>

                            <TableCell align="center">
                              {/* reenvios */}
                            </TableCell>
                            <TableCell align="center">
                              {/* reenvios */}
                            </TableCell>

                            <TableCell align="center">
                              {user.profile === "admin" && (
                                <>
                                  <Tooltip title="Editar Página">
                                    <IconButton
                                      size="small"
                                      onClick={() => handleEditMeta(meta)}
                                    >
                                      <Edit />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Deletar Página">
                                    <IconButton
                                      size="small"
                                      onClick={(e) => {
                                        handleOpenConfirmationModal(
                                          "deleteMeta",
                                          meta.id
                                        );
                                      }}
                                    >
                                      <DeleteOutline />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                    )}
                </>
              )}
            </TableBody>
          </Table>
        </Paper>
      </MainContainer>
      {user.profile === "admin" && (
        <ConnectionsInatives
          whatsApps={whatsApps}
          metas={metas}
          setMetas={setMetas}
        />
      )}
    </>
  );
};

export default Connections;
